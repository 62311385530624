/* eslint-disable */
/* This file was automatically generated and should not be edited manually. */

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  YearMonth: { input: any; output: any; }
};

export type GQLDELIVERYAps = {
  __typename?: 'Aps';
  helpUrl: Scalars['String']['output'];
  medicalInstitutionInfoChangeUrl: Scalars['String']['output'];
  paymentManagementLoginUrl: Scalars['String']['output'];
  prescriptionHelpUrl: Scalars['String']['output'];
};

export type GQLDELIVERYApsError = {
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type GQLDELIVERYApsInternalError = GQLDELIVERYApsError & {
  __typename?: 'ApsInternalError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type GQLDELIVERYBillableEvent = {
  __typename?: 'BillableEvent';
  billableStatus: GQLDELIVERYBillableStatus;
  createdAt: Scalars['Date']['output'];
  executer?: Maybe<GQLDELIVERYMedicalInstitutionMember>;
  id: Scalars['ID']['output'];
  prescriptionEvent?: Maybe<GQLDELIVERYPrescriptionEvent>;
};

export enum GQLDELIVERYBillableStatus {
  Billable = 'BILLABLE',
  Nonbillable = 'NONBILLABLE'
}

export type GQLDELIVERYBillingAccount = {
  __typename?: 'BillingAccount';
  billingAccountId: Scalars['String']['output'];
  contactEmailAddress: Scalars['String']['output'];
  contactName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  medicalInstitutionId: Scalars['String']['output'];
  sendEmail: Scalars['Boolean']['output'];
  sendMail: Scalars['Boolean']['output'];
};

export enum GQLDELIVERYBillingCategory {
  CureappHt = 'CUREAPP_HT',
  CureappSc = 'CUREAPP_SC',
  Others = 'OTHERS',
  Relivion = 'RELIVION'
}

export type GQLDELIVERYBillingFile = {
  __typename?: 'BillingFile';
  filename: Scalars['String']['output'];
  id: Scalars['String']['output'];
  month: Scalars['String']['output'];
  year: Scalars['String']['output'];
};

export type GQLDELIVERYBillingFiles = {
  __typename?: 'BillingFiles';
  deliveryStatement?: Maybe<GQLDELIVERYBillingFile>;
  invoice?: Maybe<GQLDELIVERYBillingFile>;
};

export type GQLDELIVERYBillingMonthlySummary = {
  __typename?: 'BillingMonthlySummary';
  pdtMonthlySummaries: Array<GQLDELIVERYPdtBillingMonthlySummary>;
  rentalDevices: Array<GQLDELIVERYRentalDeviceBillingMonthlySummary>;
};

export type GQLDELIVERYBillingSchedule = {
  __typename?: 'BillingSchedule';
  billableEvents: Array<GQLDELIVERYBillableEvent>;
  billableStatus: GQLDELIVERYBillableStatus;
  billingFixedDate: Scalars['Date']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  isBillingRequested: Scalars['Boolean']['output'];
  isBillingSettled: Scalars['Boolean']['output'];
  month: Scalars['Int']['output'];
  unitAmount?: Maybe<GQLDELIVERYUnitAmount>;
  year: Scalars['Int']['output'];
};

/**
 * Filter for billing schedules.
 * If start is not specified, you can get schedules from the beginning.
 * If end is not specified, you can get schedules until the latest.
 * If start = end, you can get a schedule for the specified month.
 * If start > end, you can get no schedules.
 * If you not specify start and end, you can get schedules from the beginning until the latest.
 *
 * If you specify billableStatuses, you can get schedules with the specified billable statuses.
 * If you not specify billableStatuses, you can get schedules with all billable statuses.
 */
export type GQLDELIVERYBillingScheduleFilter = {
  billableStatuses?: InputMaybe<Array<GQLDELIVERYBillableStatus>>;
  end?: InputMaybe<Scalars['YearMonth']['input']>;
  start?: InputMaybe<Scalars['YearMonth']['input']>;
};

export type GQLDELIVERYBillingScheduleFilterInput = {
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type GQLDELIVERYBillingScheduleFilterTermInput = {
  end?: InputMaybe<Scalars['YearMonth']['input']>;
  start?: InputMaybe<Scalars['YearMonth']['input']>;
};

export type GQLDELIVERYCancelReservedSenteiRyoyoError = {
  __typename?: 'CancelReservedSenteiRyoyoError';
  code: GQLDELIVERYSenteiRyoyoErrorCode;
  message: Scalars['String']['output'];
};

export enum GQLDELIVERYCancelStatus {
  Canceled = 'CANCELED',
  Pending = 'PENDING'
}

export type GQLDELIVERYClientCertificate = {
  __typename?: 'ClientCertificate';
  createdAt: Scalars['Date']['output'];
  domain: Scalars['String']['output'];
  downloadUrl?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isUsed: Scalars['Boolean']['output'];
  medicalInstitution: GQLDELIVERYMedicalInstitution;
  name: Scalars['String']['output'];
  password?: Maybe<Scalars['String']['output']>;
  pfx?: Maybe<Scalars['String']['output']>;
  s3BucketKey?: Maybe<Scalars['String']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQLDELIVERYContract = {
  __typename?: 'Contract';
  createdAt: Scalars['Date']['output'];
  deliveryOrders?: Maybe<GQLDELIVERYDeliveryOrdersPayload>;
  id: Scalars['ID']['output'];
  lendingRentalProductDetails?: Maybe<Array<GQLDELIVERYRentalProduct>>;
  lendingRentalProducts?: Maybe<Array<GQLDELIVERYLendingRentalProduct>>;
  medicalInstitution: GQLDELIVERYMedicalInstitution;
  name: Scalars['String']['output'];
  pdt: GQLDELIVERYPdt;
  remarks?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  senteiRyoyo?: Maybe<GQLDELIVERYSenteiRyoyoContract>;
  suspensionDays: Scalars['Int']['output'];
  unitAmountTable?: Maybe<Array<GQLDELIVERYUnitAmount>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  wholesaler?: Maybe<Scalars['String']['output']>;
};


export type GQLDELIVERYContractDeliveryOrdersArgs = {
  input?: InputMaybe<GQLDELIVERYDeliveryOrderQueryInput>;
};

export type GQLDELIVERYCreateShippingRequestPayload = GQLDELIVERYApsInternalError | GQLDELIVERYEmptyUnshippedDeliveryOrderError | GQLDELIVERYShippingRequestFile;

export type GQLDELIVERYDeliveryAddress = {
  __typename?: 'DeliveryAddress';
  city: Scalars['String']['output'];
  isMedicalInstitutionAddress?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  otherAddress: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  prefecture: Scalars['String']['output'];
  prefectureId: Scalars['Int']['output'];
};

export type GQLDELIVERYDeliveryCompany = {
  __typename?: 'DeliveryCompany';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQLDELIVERYDeliveryContract = {
  __typename?: 'DeliveryContract';
  deliveryCompany?: Maybe<GQLDELIVERYDeliveryCompany>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  vendor?: Maybe<GQLDELIVERYVendor>;
};

export type GQLDELIVERYDeliveryOrder = {
  __typename?: 'DeliveryOrder';
  canCancel?: Maybe<Scalars['Boolean']['output']>;
  city: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  deliveryContract?: Maybe<GQLDELIVERYDeliveryContract>;
  deliveryProducts?: Maybe<Array<GQLDELIVERYDeliveryProduct>>;
  deliveryStatus?: Maybe<GQLDELIVERYDeliveryStatus>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDummy?: Maybe<Scalars['Boolean']['output']>;
  isShippingRequested?: Maybe<Scalars['Boolean']['output']>;
  medicalInstitution?: Maybe<GQLDELIVERYMedicalInstitution>;
  name: Scalars['String']['output'];
  orderClass?: Maybe<GQLDELIVERYOrderClass>;
  orderType: GQLDELIVERYOrderTypeEnum;
  otherAddress?: Maybe<Scalars['String']['output']>;
  pdt?: Maybe<GQLDELIVERYPdt>;
  phoneNumber: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  prefecture: Scalars['String']['output'];
  prefectureId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type GQLDELIVERYDeliveryOrderFilterInput = {
  orderType?: InputMaybe<GQLDELIVERYOrderTypeEnum>;
};

export type GQLDELIVERYDeliveryOrderQueryInput = {
  filter?: InputMaybe<GQLDELIVERYDeliveryOrderFilterInput>;
  numberPerPage?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<GQLDELIVERYDeliveryOrderQuerySortInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export enum GQLDELIVERYDeliveryOrderQuerySortField {
  OrderedDate = 'OrderedDate'
}

export type GQLDELIVERYDeliveryOrderQuerySortInput = {
  field: GQLDELIVERYDeliveryOrderQuerySortField;
  value: GQLDELIVERYQueryOrderValue;
};

export type GQLDELIVERYDeliveryOrdersPayload = {
  __typename?: 'DeliveryOrdersPayload';
  edges: Array<GQLDELIVERYDeliveryOrder>;
  pageCount: Scalars['Int']['output'];
};

export type GQLDELIVERYDeliveryProduct = {
  __typename?: 'DeliveryProduct';
  createdAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  product?: Maybe<GQLDELIVERYProduct>;
  quantity: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type GQLDELIVERYDeliveryStatus = {
  __typename?: 'DeliveryStatus';
  code: GQLDELIVERYDeliveryStatusCode;
};

export enum GQLDELIVERYDeliveryStatusCode {
  Shipped = 'SHIPPED',
  ShippingRequest = 'SHIPPING_REQUEST',
  Unshipped = 'UNSHIPPED'
}

export type GQLDELIVERYDeliveryStatusError = {
  __typename?: 'DeliveryStatusError';
  code: GQLDELIVERYDeliveryStatusErrorCode;
  message: Scalars['String']['output'];
};

export enum GQLDELIVERYDeliveryStatusErrorCode {
  AlreadyShipped = 'AlreadyShipped',
  CannotCancel = 'CannotCancel',
  CannotEdit = 'CannotEdit'
}

export type GQLDELIVERYEmptyUnshippedDeliveryOrderError = GQLDELIVERYApsError & {
  __typename?: 'EmptyUnshippedDeliveryOrderError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type GQLDELIVERYLendingRentalProduct = {
  __typename?: 'LendingRentalProduct';
  product: GQLDELIVERYProduct;
  quantity: Scalars['Int']['output'];
};

export type GQLDELIVERYMedicalInstitution = {
  __typename?: 'MedicalInstitution';
  canPrescribe: Scalars['Boolean']['output'];
  city: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deliveryName?: Maybe<Scalars['String']['output']>;
  /** 医療機関は特別価格期間を含でいたかどうか.(現在かどうかではなく、過去に特別価格期間を含む場合はtrueになる) */
  hadSpecialPricePeriod?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isDummy?: Maybe<Scalars['Boolean']['output']>;
  latestMaterialOrderedAddress?: Maybe<GQLDELIVERYDeliveryAddress>;
  mailAddress: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** フロントで表示する前月分の利用分の締め作業の確認ボタンを表示などの判定に使用 */
  needToConfirmPreviousMonthClosingEvent?: Maybe<Scalars['Boolean']['output']>;
  otherAddress: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  prefecture: Scalars['String']['output'];
  prefectureId: Scalars['Int']['output'];
  prescriptionInputConfiguration: GQLDELIVERYPrescriptionInputConfiguration;
  previousMonthClosingEvent?: Maybe<GQLDELIVERYMonthClosingEvent>;
  representativeName: Scalars['String']['output'];
  /**
   * 医療機関が契約しているPDTの締め日(締め日 < 現在時刻)の-1秒の日付を返す(-1秒はフロントで表示する場合を考慮)
   * HTの場合、11日が締め日なので10日23時59秒. UTCの時刻、表記で返す.
   */
  upcomingMonthClosingDate?: Maybe<Scalars['Date']['output']>;
  updatedAt: Scalars['Date']['output'];
  welcomeMailSentAt?: Maybe<Scalars['Date']['output']>;
};

export type GQLDELIVERYMedicalInstitutionMember = {
  __typename?: 'MedicalInstitutionMember';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  medicalInstitution: GQLDELIVERYMedicalInstitution;
  name: Scalars['String']['output'];
  position: Array<GQLDELIVERYMedicalInstitutionMemberPosition>;
  role: Array<GQLDELIVERYMedicalInstitutionMemberRole>;
  sex: GQLDELIVERYSex;
  status: GQLDELIVERYMedicalInstitutionMemberStatus;
  updatedAt: Scalars['Date']['output'];
};

export enum GQLDELIVERYMedicalInstitutionMemberPosition {
  Doctor = 'DOCTOR',
  Nurse = 'NURSE',
  Staff = 'STAFF'
}

export enum GQLDELIVERYMedicalInstitutionMemberRole {
  Admin = 'ADMIN',
  Staff = 'STAFF'
}

export enum GQLDELIVERYMedicalInstitutionMemberStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  Invited = 'INVITED',
  SecretcodeReissued = 'SECRETCODE_REISSUED'
}

export type GQLDELIVERYMessagePayload = {
  __typename?: 'MessagePayload';
  message: Scalars['String']['output'];
};

export type GQLDELIVERYMonthClosingEvent = {
  __typename?: 'MonthClosingEvent';
  confirmed: Scalars['Boolean']['output'];
  createdAt: Scalars['Date']['output'];
  executer?: Maybe<GQLDELIVERYMedicalInstitutionMember>;
  id: Scalars['ID']['output'];
  medicalInstitutionId: Scalars['String']['output'];
  targetMonth: Scalars['Int']['output'];
  targetYear: Scalars['Int']['output'];
};

export type GQLDELIVERYMonthlyBillingFile = {
  __typename?: 'MonthlyBillingFile';
  billingFiles: GQLDELIVERYBillingFiles;
  month: Scalars['String']['output'];
};

export type GQLDELIVERYMutation = {
  __typename?: 'Mutation';
  createShippingRequest: GQLDELIVERYCreateShippingRequestPayload;
};

/**
 * PRESCRIPTION_NOT_FOUND： 処方が存在しない
 * PRESCRIPTION_CANCELED： 処方が取り消し済み
 * PRESCRIPTION_NOT_ACTIVATED： 処方がアクティベートされていない
 * PRESCRIPTION_CODE_NOT_FOUND： 処方コードが存在しない
 * PRESCRIPTION_PDT_UNSUPPORTED： 処方のPDTが対応していない（現状対応しているのはHTのみ）
 * INTERNAL_ERROR： 内部エラー（DB書き込み失敗を含む）
 */
export enum GQLDELIVERYNextAppPostExpiryErrorType {
  InternalError = 'INTERNAL_ERROR',
  PrescriptionCanceled = 'PRESCRIPTION_CANCELED',
  PrescriptionCodeNotFound = 'PRESCRIPTION_CODE_NOT_FOUND',
  PrescriptionNotActivated = 'PRESCRIPTION_NOT_ACTIVATED',
  PrescriptionNotFound = 'PRESCRIPTION_NOT_FOUND',
  PrescriptionPdtUnsupported = 'PRESCRIPTION_PDT_UNSUPPORTED'
}

export type GQLDELIVERYNotification = {
  __typename?: 'Notification';
  body: Scalars['String']['output'];
  endAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  notificationPriority: GQLDELIVERYNotificationPriority;
  startFrom: Scalars['Date']['output'];
  title: Scalars['String']['output'];
  viewed: Scalars['Boolean']['output'];
};

export enum GQLDELIVERYNotificationPriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type GQLDELIVERYNotificationToast = {
  __typename?: 'NotificationToast';
  body?: Maybe<Scalars['String']['output']>;
  endAt: Scalars['Date']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  medicalInstitutionId?: Maybe<Scalars['String']['output']>;
  startFrom: Scalars['Date']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type GQLDELIVERYOneTimeCode = {
  __typename?: 'OneTimeCode';
  code: Scalars['String']['output'];
};

export type GQLDELIVERYOptionContract = {
  __typename?: 'OptionContract';
  cancelRequestedAt?: Maybe<Scalars['Date']['output']>;
  createdAt: Scalars['Date']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  optionDiscount?: Maybe<GQLDELIVERYOptionDiscount>;
  optionPaymentPlan: GQLDELIVERYOptionPaymentPlan;
  startDate: Scalars['Date']['output'];
};

export type GQLDELIVERYOptionCoupon = {
  __typename?: 'OptionCoupon';
  amountOff: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQLDELIVERYOptionDiscount = {
  __typename?: 'OptionDiscount';
  id: Scalars['ID']['output'];
  optionCoupon: GQLDELIVERYOptionCoupon;
};

export type GQLDELIVERYOptionPaymentPlan = {
  __typename?: 'OptionPaymentPlan';
  id: Scalars['ID']['output'];
  optionPlan: GQLDELIVERYOptionPlan;
  paymentCycle: GQLDELIVERYPaymentCycle;
  price: Scalars['Int']['output'];
};

export type GQLDELIVERYOptionPlan = {
  __typename?: 'OptionPlan';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQLDELIVERYOrderClass = {
  __typename?: 'OrderClass';
  code: GQLDELIVERYOrderClassCode;
  name: Scalars['String']['output'];
};

export enum GQLDELIVERYOrderClassCode {
  MedicalInstitutionClientCertificatePasswordShipping = 'MEDICAL_INSTITUTION_CLIENT_CERTIFICATE_PASSWORD_SHIPPING',
  MedicalInstitutionReplacementShipping = 'MEDICAL_INSTITUTION_REPLACEMENT_SHIPPING',
  MedicalInstitutionShipping = 'MEDICAL_INSTITUTION_SHIPPING',
  PatientReplacementShipping = 'PATIENT_REPLACEMENT_SHIPPING',
  PatientShipping = 'PATIENT_SHIPPING'
}

export enum GQLDELIVERYOrderTypeEnum {
  Material = 'MATERIAL',
  None = 'NONE',
  Rental = 'RENTAL',
  WithPrescription = 'WITH_PRESCRIPTION'
}

export type GQLDELIVERYPdtBillingMonthlySummariesInput = {
  billingScheduleTerm: GQLDELIVERYBillingScheduleFilterTermInput;
  isSettled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQLDELIVERYPdtBillingMonthlySummary = {
  __typename?: 'PDTBillingMonthlySummary';
  amount: Scalars['Int']['output'];
  billableStatus: GQLDELIVERYBillableStatus;
  billingCategory: GQLDELIVERYBillingCategory;
  id: Scalars['ID']['output'];
  month: Scalars['Int']['output'];
  numberOfPrescriptions: Scalars['Int']['output'];
  pdts: Array<GQLDELIVERYPdt>;
  senteiRyoyo: Scalars['Boolean']['output'];
  specialPrice: Scalars['Boolean']['output'];
  year: Scalars['Int']['output'];
};

export type GQLDELIVERYPatient = {
  __typename?: 'Patient';
  birthday?: Maybe<Scalars['Date']['output']>;
  karteNumber: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  prescriptionCodeId: Scalars['String']['output'];
  prescriptionId: Scalars['String']['output'];
  sex?: Maybe<GQLDELIVERYSex>;
};

export type GQLDELIVERYPaymentCycle = {
  __typename?: 'PaymentCycle';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQLDELIVERYPdt = {
  __typename?: 'Pdt';
  canOrderMaterial?: Maybe<Scalars['Boolean']['output']>;
  canPrintPrescription: Scalars['Boolean']['output'];
  category: Scalars['String']['output'];
  changeableBillingStatus: Scalars['Boolean']['output'];
  closingDateOfMonth: Scalars['String']['output'];
  code: Scalars['String']['output'];
  contact?: Maybe<GQLDELIVERYPdtContact>;
  /** @deprecated Pdtには複数の医療機関が紐づくはずで、一つになることはない */
  contract?: Maybe<GQLDELIVERYContract>;
  createdAt: Scalars['Date']['output'];
  description: Scalars['String']['output'];
  enableFlag: Scalars['Boolean']['output'];
  hasAppSiteRedirect: Scalars['Boolean']['output'];
  iconPath: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  integratedStoreUrl?: Maybe<Scalars['String']['output']>;
  isClinicalTrial: Scalars['Boolean']['output'];
  isDiscontinued: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  needDeliveryAddressForPrescription: Scalars['Boolean']['output'];
  needOneTimeCodeForSiteUrl: Scalars['Boolean']['output'];
  officialUrl: Scalars['String']['output'];
  pdtDocuments: Array<GQLDELIVERYPdtDocument>;
  pdtOriginalCode?: Maybe<Scalars['String']['output']>;
  products: Array<GQLDELIVERYProduct>;
  siteUrl: Scalars['String']['output'];
  storeUrlApple: Scalars['String']['output'];
  storeUrlGoogle: Scalars['String']['output'];
  supportUrl: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  vendor: GQLDELIVERYVendor;
  version?: Maybe<GQLDELIVERYPdtVersion>;
  versions: Array<GQLDELIVERYPdtVersion>;
};


export type GQLDELIVERYPdtProductsArgs = {
  input?: InputMaybe<GQLDELIVERYProductFilterInput>;
};

export type GQLDELIVERYPdtContact = {
  __typename?: 'PdtContact';
  businessHours?: Maybe<Scalars['String']['output']>;
  faxNumber?: Maybe<Scalars['String']['output']>;
  helpUrl?: Maybe<Scalars['String']['output']>;
  helpUrlUtm?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mailAddress?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type GQLDELIVERYPdtDocument = {
  __typename?: 'PdtDocument';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  isUrlS3Path: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  url: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export enum GQLDELIVERYPdtType {
  Ht = 'HT',
  Relivion = 'RELIVION',
  Sc = 'SC'
}

export type GQLDELIVERYPdtVersion = {
  __typename?: 'PdtVersion';
  createdAt: Scalars['Date']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  releaseDate: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
  version: Scalars['String']['output'];
};

export type GQLDELIVERYPrescription = {
  __typename?: 'Prescription';
  billingSchedules: Array<GQLDELIVERYBillingSchedule>;
  birthday?: Maybe<Scalars['Date']['output']>;
  canPrint: Scalars['Boolean']['output'];
  canTransitionToCanceled: GQLDELIVERYTransitionCheckResult;
  canTransitionToExtended: GQLDELIVERYTransitionCheckResult;
  canTransitionToResumed: GQLDELIVERYTransitionCheckResult;
  canTransitionToSenteiRyoyoReserveCanceled: GQLDELIVERYTransitionCheckResult;
  canTransitionToSenteiRyoyoReservedOrStarted: GQLDELIVERYTransitionCheckResult;
  canTransitionToSuspended: GQLDELIVERYTransitionCheckResult;
  canTransitionToTerminated: GQLDELIVERYTransitionCheckResult;
  contract: GQLDELIVERYContract;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  karteNumber: Scalars['String']['output'];
  medicalInstitutionMember: GQLDELIVERYMedicalInstitutionMember;
  monthsFromPrescribed: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  pdt: GQLDELIVERYPdt;
  prescriptionCureAppPdtCode?: Maybe<GQLDELIVERYPrescriptionCureAppPdtCode>;
  prescriptionDate: Scalars['Date']['output'];
  prescriptionDeliveryOrder?: Maybe<GQLDELIVERYPrescriptionDeliveryOrder>;
  prescriptionEvents?: Maybe<Array<GQLDELIVERYPrescriptionEvent>>;
  senteiRyoyo?: Maybe<GQLDELIVERYPrescriptionSenteiRyoyo>;
  sex?: Maybe<GQLDELIVERYSex>;
  status?: Maybe<GQLDELIVERYPrescriptionStatus>;
  terminatesFrom?: Maybe<Scalars['Date']['output']>;
  unitAmount?: Maybe<GQLDELIVERYUnitAmount>;
  updatedAt: Scalars['Date']['output'];
  usingNextAppPostExpiry: Scalars['Boolean']['output'];
};


export type GQLDELIVERYPrescriptionBillingSchedulesArgs = {
  filter?: InputMaybe<GQLDELIVERYBillingScheduleFilter>;
};

export enum GQLDELIVERYPrescriptionCodeStatusLabel {
  Expired = 'EXPIRED',
  Fulfilled = 'FULFILLED',
  Suspended = 'SUSPENDED',
  Terminated = 'TERMINATED',
  Unused = 'UNUSED',
  Used = 'USED'
}

export type GQLDELIVERYPrescriptionCureAppPdtCode = {
  __typename?: 'PrescriptionCureAppPdtCode';
  activationLimit?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  pdtCode?: Maybe<Scalars['String']['output']>;
  pdtCodeId: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  statusLabel?: Maybe<GQLDELIVERYPrescriptionCodeStatusLabel>;
};

export type GQLDELIVERYPrescriptionDeliveryOrder = {
  __typename?: 'PrescriptionDeliveryOrder';
  canCancel: Scalars['Boolean']['output'];
  canEdit: Scalars['Boolean']['output'];
  canRegister: Scalars['Boolean']['output'];
  city?: Maybe<Scalars['String']['output']>;
  deliveryOrderId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  isShippingRequested?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  otherAddress?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  prefectureId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<GQLDELIVERYDeliveryStatus>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type GQLDELIVERYPrescriptionEvent = {
  __typename?: 'PrescriptionEvent';
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  prescriptionId: Scalars['String']['output'];
  type: GQLDELIVERYPrescriptionEventType;
};

export enum GQLDELIVERYPrescriptionEventType {
  CancelPrescription = 'CANCEL_PRESCRIPTION',
  CreatePrescription = 'CREATE_PRESCRIPTION',
  ExtendPrescriptionCodeExpiry = 'EXTEND_PRESCRIPTION_CODE_EXPIRY',
  ResumePrescription = 'RESUME_PRESCRIPTION',
  SenteiRyoyoReserved = 'SENTEI_RYOYO_RESERVED',
  SenteiRyoyoReserveCanceled = 'SENTEI_RYOYO_RESERVE_CANCELED',
  SenteiRyoyoStarted = 'SENTEI_RYOYO_STARTED',
  SenteiRyoyoTerminated = 'SENTEI_RYOYO_TERMINATED',
  SenteiRyoyoTerminateReserved = 'SENTEI_RYOYO_TERMINATE_RESERVED',
  SuspendPrescription = 'SUSPEND_PRESCRIPTION',
  TerminatePrescription = 'TERMINATE_PRESCRIPTION',
  UsePrescription = 'USE_PRESCRIPTION'
}

export type GQLDELIVERYPrescriptionInputConfiguration = {
  __typename?: 'PrescriptionInputConfiguration';
  birthday: GQLDELIVERYPrescriptionInputConfigurationType;
  fullName: GQLDELIVERYPrescriptionInputConfigurationType;
  note: GQLDELIVERYPrescriptionInputConfigurationType;
  sex: GQLDELIVERYPrescriptionInputConfigurationType;
};

export enum GQLDELIVERYPrescriptionInputConfigurationItem {
  Birthday = 'BIRTHDAY',
  Fullname = 'FULLNAME',
  Note = 'NOTE',
  Sex = 'SEX'
}

/**
 * REQUIRED: 入力必須
 * OPTIONAL_VISIBLE_CREATE: 入力任意 & 新規処方画面で表示する
 * OPTIONAL_HIDDEN_CREATE: 入力任意 & 新規処方画面で表示しない (編集画面や詳細画面では表示する)
 */
export enum GQLDELIVERYPrescriptionInputConfigurationType {
  OptionalHiddenCreate = 'OPTIONAL_HIDDEN_CREATE',
  OptionalVisibleCreate = 'OPTIONAL_VISIBLE_CREATE',
  Required = 'REQUIRED'
}

export type GQLDELIVERYPrescriptionSenteiRyoyo = {
  __typename?: 'PrescriptionSenteiRyoyo';
  id: Scalars['ID']['output'];
  reserved: Scalars['Boolean']['output'];
  startDate: Scalars['Date']['output'];
};

export enum GQLDELIVERYPrescriptionStatus {
  Expired = 'EXPIRED',
  Fulfilled = 'FULFILLED',
  SenteiRyoyoUsing = 'SENTEI_RYOYO_USING',
  Suspended = 'SUSPENDED',
  Terminated = 'TERMINATED',
  Unused = 'UNUSED',
  Used = 'USED'
}

export type GQLDELIVERYProduct = {
  __typename?: 'Product';
  availableOrderQuantities?: Maybe<Array<Scalars['Int']['output']>>;
  code: Scalars['String']['output'];
  contentPath?: Maybe<Scalars['String']['output']>;
  contentPreviewUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  deliveryContract?: Maybe<GQLDELIVERYDeliveryContract>;
  description: Scalars['String']['output'];
  discontinuedAt?: Maybe<Scalars['Date']['output']>;
  externalItemCode: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  imagePath?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orderType: GQLDELIVERYOrderTypeEnum;
  productClass?: Maybe<GQLDELIVERYProductClass>;
  productSets?: Maybe<Array<GQLDELIVERYProductSet>>;
  receiptClass?: Maybe<GQLDELIVERYReceiptClass>;
  shippingDeadlineAdjustmentClass?: Maybe<GQLDELIVERYShippingDeadlineAdjustmentClass>;
  shippingDeadlineManagementClass?: Maybe<GQLDELIVERYShippingDeadlineManagementClass>;
  shippingDeadlineOffsetPeriod?: Maybe<Scalars['Int']['output']>;
  stockManagementClass?: Maybe<GQLDELIVERYStockManagementClass>;
  unitSize?: Maybe<Scalars['Int']['output']>;
};

export type GQLDELIVERYProductClass = {
  __typename?: 'ProductClass';
  code: GQLDELIVERYProductClassCode;
};

export enum GQLDELIVERYProductClassCode {
  Set = 'SET',
  Single = 'SINGLE'
}

export type GQLDELIVERYProductFilterInput = {
  discontinued: Scalars['Boolean']['input'];
  orderType?: InputMaybe<GQLDELIVERYOrderTypeEnum>;
};

export type GQLDELIVERYProductSet = {
  __typename?: 'ProductSet';
  childProduct: GQLDELIVERYProduct;
  product: GQLDELIVERYProduct;
};

export type GQLDELIVERYQuery = {
  __typename?: 'Query';
  deliveryOrder?: Maybe<GQLDELIVERYDeliveryOrder>;
  shippingRequestFiles: Array<GQLDELIVERYShippingRequestFile>;
};


export type GQLDELIVERYQueryDeliveryOrderArgs = {
  id: Scalars['ID']['input'];
};


export type GQLDELIVERYQueryShippingRequestFilesArgs = {
  input: GQLDELIVERYShippingRequestFilesInput;
};

export enum GQLDELIVERYQueryOrderValue {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type GQLDELIVERYReceiptClass = {
  __typename?: 'ReceiptClass';
  code?: Maybe<GQLDELIVERYReceiptClassCode>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum GQLDELIVERYReceiptClassCode {
  Yt000 = 'YT000',
  Yt001 = 'YT001',
  Yt008 = 'YT008',
  Yt009 = 'YT009',
  Yt010 = 'YT010'
}

export type GQLDELIVERYRentalDeviceBillingMonthlySummary = {
  __typename?: 'RentalDeviceBillingMonthlySummary';
  amount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  month: Scalars['Int']['output'];
  number: Scalars['Int']['output'];
  pdt: GQLDELIVERYPdt;
  year: Scalars['Int']['output'];
};

export type GQLDELIVERYRentalProduct = {
  __typename?: 'RentalProduct';
  contract: GQLDELIVERYContract;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  medicalInstitution: GQLDELIVERYMedicalInstitution;
  product: GQLDELIVERYProduct;
  rentalDate: Scalars['Date']['output'];
  returnDate?: Maybe<Scalars['Date']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQLDELIVERYReserveOrStartSenteiRyoyoError = {
  __typename?: 'ReserveOrStartSenteiRyoyoError';
  code: GQLDELIVERYSenteiRyoyoErrorCode;
  message: Scalars['String']['output'];
  senteiRyoyoPrescriptionCode?: Maybe<GQLDELIVERYSenteiRyoyoPrescriptionCode>;
};

export type GQLDELIVERYSenteiRyoyoContract = {
  __typename?: 'SenteiRyoyoContract';
  endDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  restricted: Scalars['Boolean']['output'];
  startDate: Scalars['Date']['output'];
};

export enum GQLDELIVERYSenteiRyoyoErrorCode {
  AlreadyReservedOrStarted = 'AlreadyReservedOrStarted',
  Restricted = 'Restricted',
  Uncontracted = 'Uncontracted',
  UnsupportedPdt = 'UnsupportedPdt',
  UnsupportedPrescriptionStatus = 'UnsupportedPrescriptionStatus'
}

export type GQLDELIVERYSenteiRyoyoPrescriptionCode = {
  __typename?: 'SenteiRyoyoPrescriptionCode';
  activationLimit?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
};

export enum GQLDELIVERYSex {
  Female = 'FEMALE',
  Male = 'MALE',
  Unspecified = 'UNSPECIFIED'
}

export type GQLDELIVERYShippingDeadlineAdjustmentClass = {
  __typename?: 'ShippingDeadlineAdjustmentClass';
  code: GQLDELIVERYShippingDeadlineAdjustmentClassCode;
};

export enum GQLDELIVERYShippingDeadlineAdjustmentClassCode {
  EndOfMonth = 'END_OF_MONTH',
  EndOfPreviousMonth = 'END_OF_PREVIOUS_MONTH',
  NoAdjustment = 'NO_ADJUSTMENT'
}

export type GQLDELIVERYShippingDeadlineManagementClass = {
  __typename?: 'ShippingDeadlineManagementClass';
  code: GQLDELIVERYShippingDeadlineManagementClassCode;
};

export enum GQLDELIVERYShippingDeadlineManagementClassCode {
  Managed = 'MANAGED',
  NotManaged = 'NOT_MANAGED'
}

export type GQLDELIVERYShippingRequestFile = {
  __typename?: 'ShippingRequestFile';
  createdAt: Scalars['Date']['output'];
  csvSignedURL: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type GQLDELIVERYShippingRequestFilesInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  order: GQLDELIVERYShippingRequestFilesOrderInput;
};

export enum GQLDELIVERYShippingRequestFilesOrderField {
  CreatedAt = 'CREATED_AT'
}

export type GQLDELIVERYShippingRequestFilesOrderInput = {
  field: GQLDELIVERYShippingRequestFilesOrderField;
  value: GQLDELIVERYQueryOrderValue;
};

export type GQLDELIVERYStockManagementClass = {
  __typename?: 'StockManagementClass';
  code: GQLDELIVERYStockManagementClassCode;
};

export enum GQLDELIVERYStockManagementClassCode {
  Managed = 'MANAGED',
  NotManaged = 'NOT_MANAGED'
}

export type GQLDELIVERYTransitionCheckResult = {
  __typename?: 'TransitionCheckResult';
  isPossible: Scalars['Boolean']['output'];
  reasons?: Maybe<Array<Scalars['String']['output']>>;
};

export type GQLDELIVERYUnitAmount = {
  __typename?: 'UnitAmount';
  amount: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  startFrom?: Maybe<Scalars['Date']['output']>;
  type: Scalars['String']['output'];
};

export type GQLDELIVERYUser = {
  __typename?: 'User';
  medicalInstitution?: Maybe<GQLDELIVERYMedicalInstitution>;
  medicalInstitutionMember?: Maybe<GQLDELIVERYMedicalInstitutionMember>;
};

export type GQLDELIVERYUserClientCertificate = {
  __typename?: 'UserClientCertificate';
  createdAt: Scalars['Date']['output'];
  domain: Scalars['String']['output'];
  expiresAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  serialNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQLDELIVERYVendor = {
  __typename?: 'Vendor';
  address?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  licenseNumber?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  vendorOffices?: Maybe<Array<GQLDELIVERYVendorOffice>>;
};

export type GQLDELIVERYVendorOffice = {
  __typename?: 'VendorOffice';
  address: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type GQLDELIVERYYearlyBillingFile = {
  __typename?: 'YearlyBillingFile';
  monthlyFiles: Array<GQLDELIVERYMonthlyBillingFile>;
  year: Scalars['String']['output'];
};

export enum GQLDELIVERYDeletePrescriptionRequestStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type GQLDELIVERYCreateShippingRequestMutationVariables = Exact<{ [key: string]: never; }>;


export type GQLDELIVERYCreateShippingRequestMutation = { __typename?: 'Mutation', createShippingRequest: { __typename?: 'ApsInternalError', code: string, message: string } | { __typename?: 'EmptyUnshippedDeliveryOrderError', code: string, message: string } | { __typename?: 'ShippingRequestFile', id: string, csvSignedURL: string, filename: string, createdAt: any } };

export type GQLDELIVERYShippingRequestFileFragmentFragment = { __typename?: 'ShippingRequestFile', id: string, csvSignedURL: string, filename: string, createdAt: any };

export type GQLDELIVERYGetShippingRequestFilesQueryVariables = Exact<{
  input: GQLDELIVERYShippingRequestFilesInput;
}>;


export type GQLDELIVERYGetShippingRequestFilesQuery = { __typename?: 'Query', shippingRequestFiles: Array<{ __typename?: 'ShippingRequestFile', id: string, csvSignedURL: string, filename: string, createdAt: any }> };

export const ShippingRequestFileFragmentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ShippingRequestFileFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ShippingRequestFile"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"csvSignedURL"}},{"kind":"Field","name":{"kind":"Name","value":"filename"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]} as unknown as DocumentNode<GQLDELIVERYShippingRequestFileFragmentFragment, unknown>;
export const CreateShippingRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateShippingRequest"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createShippingRequest"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ShippingRequestFile"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ShippingRequestFileFragment"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"EmptyUnshippedDeliveryOrderError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ApsInternalError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ShippingRequestFileFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ShippingRequestFile"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"csvSignedURL"}},{"kind":"Field","name":{"kind":"Name","value":"filename"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]} as unknown as DocumentNode<GQLDELIVERYCreateShippingRequestMutation, GQLDELIVERYCreateShippingRequestMutationVariables>;
export const GetShippingRequestFilesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetShippingRequestFiles"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ShippingRequestFilesInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"shippingRequestFiles"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ShippingRequestFile"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ShippingRequestFileFragment"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ShippingRequestFileFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ShippingRequestFile"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"csvSignedURL"}},{"kind":"Field","name":{"kind":"Name","value":"filename"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]} as unknown as DocumentNode<GQLDELIVERYGetShippingRequestFilesQuery, GQLDELIVERYGetShippingRequestFilesQueryVariables>;